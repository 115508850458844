
































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import FastGameMenu from "@/components/menus/FastGameMenu.vue"
import { namespace } from "vuex-class"
import Common from "@/mixins/Common.vue"
import axios from 'axios'

const SettingsGetter = namespace("settings").Getter
const UIMutation = namespace('ui').Mutation
const AccountGetter = namespace('account').Getter

const AppProps = Vue.extend({
  data: () => {
    return {
      valid_credit_sizes: [],
      credit_btc_value: 0,
      prizes : [],
      isAccountLoaded: false
    }
  },
  metaInfo: {
    meta: [{
      vmid: 'description',
      name: 'description',
      content: `Play Classic Video Poker - Jacks or Better Online with Bitcoin, Win Big Jackpots & Cashout Instantly at the best Bitcoin Games Casino`
    },
    {
      vmid: 'title',
      name: 'title',
      content: `Videopoker`
    },
    {
      vmid: 'keywords',
      name: 'keywords',
      content: `bitcoin casino, btc casino, bch casino, crypto casino, bitcoin gambling, bitcoin casino no deposit, crypto casino no deposit, best bitcoin casino, bitcoin games`
    }],
  },
mixins: [Common],
  props: {},
  created() {
    //@ts-ignore
    this.setFooterDisabled(true)
    this.setBalanceDisabled(false)
  },
  methods: {
    async getRuleSet() {
        try {
          let res = await axios.get('/api/videopoker/ruleset')
          if(res.data.result) {
            this.valid_credit_sizes = res.data.result.valid_credit_sizes
            this.getPlatformCurrency == "BTC" ? this.credit_btc_value = 1000 : this.credit_btc_value = 50000
            return res.data.result
          } else {
            throw new Error('invalid response')
          }
        }
        catch(e) {
          this.$router.push('maintenance')
          console.log("something went wrong when trying to get the ruleset")
          return e
        }
    },
   async getPaytables() {
        try {
          let res = await axios.get('/api/videopoker/paytables')
          if(res.data.result) {
            return res.data.result
          } else {
            throw new Error('invalid response')
          }
        }
        catch(e) {
          console.log("something went wrong when trying to get the paytables")
          return e
        }
    },
    async reseed() {
        try {
          let res = await axios.post('/api/videopoker/seed')
          if(res.data.server_seed_hash) {
            return res.data.server_seed_hash
          } else {
            throw new Error('invalid response')
          }
        }
        catch(e) {
          console.log("something went wrong when trying to reseed")
          return e
        }
      },
    async initGame() {
      this.isAccountLoaded = true;
      this.initializeGameScale()
      // this next line of code is a hack that was built in the legacy codebase, we have
      // to figure out how to remove this properly
      // $(document.body).addClass('small');
      this.$loadScript("/js/legacy/videopoker.js").then(async() => {
        try {
          await this.getRuleSet()
          let prizes =  await this.getPaytables()
          this.prizes = prizes[0]
          let starting_server_seed_hash = await this.reseed()
          let creditModal = "#btc_item_" + this.classSuffixFromToken(this.credit_btc_value)
          $(creditModal).addClass("selected");
          // @ts-ignore
          // eslint-disable-next-line no-undef
          init_videopoker("123", "123", "123", prizes, starting_server_seed_hash, [], [], [], this.credit_btc_value, 10, false);
        } catch (e) {
          console.log("error", e)
        }
      })
    }
  },
  watch: {

  },
  mounted() {
    // note that its possible the usertoken is not yet available,
    // in this case the watcher will init videopoker game when the token
    // is available
    this.initGame();
  }
})
@Component({
  components: {
    FastGameMenu
  }
})
export default class VideoPoker extends AppProps {
  //@ts-ignore
  @SettingsGetter getPlatformCurrency
  @UIMutation('setFooterDisabled') setFooterDisabled
  @UIMutation('setBalanceDisabled') setBalanceDisabled
  @AccountGetter userToken
  @AccountGetter isLoggedIn
}
